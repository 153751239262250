import React from 'react';
import { MerchStyled, MerchMobileStyled } from './style';
import baktun from '../../../assets/baktun.png'
import videoMerch from '../../../assets/video.mp4'
import collection from '../../../assets/collection.png'

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useMediaQuery } from 'react-responsive';

import pessoa1 from '../../../assets/pessoa1.png'
import pessoa2 from '../../../assets/pessoa2.png'
import pessoa3 from '../../../assets/pessoa3.png'
import pessoa4 from '../../../assets/pessoa4.png'
import bandcamp from '../../../assets/logo-BC.png'

const buttonStyle = {
    background: 'none',
    color: '#fff',
    fontSize: '30px',
    border: '0px'
};

const properties = {
    prevArrow: <button style={{ ...buttonStyle, margin: '0 0 0 15px' }}><i className="fa-solid fa-chevron-left" /></button>,
    nextArrow: <button style={{ ...buttonStyle, margin: '0 15px 0 0' }}><i className="fa-solid fa-chevron-right" /></button>
}

export function Merch() {
    const isMobile = useMediaQuery({ maxWidth: 933 });

    return isMobile ? (
        <>
        <MerchMobileStyled>
            <p>MERCH</p>
            <img src={baktun} alt="BAKTUN" />
            <span>APPEREAL DESIGNED BY NOVOMUNDO</span>

            <Slide {...properties}>
                <div className="each-slide-effect">
                    <div className="slideImage" style={{ 'backgroundImage': `url(${pessoa1})` }}>
                        <div className="descImage">
                        </div>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="slideImage" style={{ 'backgroundImage': `url(${pessoa2})` }}>
                        <div className="descImage">
                        </div>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="slideImage" style={{ 'backgroundImage': `url(${pessoa3})` }}>
                        <div className="descImage">
                        </div>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="slideImage" style={{ 'backgroundImage': `url(${pessoa4})` }}>
                        <div className="descImage">
                        </div>
                    </div>
                </div>
            </Slide>

            <div className="description">
                <p className="text1">
                    Explore our tees collection, and wear with us our graphics.
                    Every design is both for Woman or Man, printed with natural products and non-toxic inks.
                    We use an handmade screen printing process for give you the complete exclusivity.
                </p>
                <p className="text2">
                    The idea is not to offer the same old and classic band T-Shirt, but something
                    wearable to everyone.
                </p>
                <p className="text3">
                    Because our music and its
                    mesasage is not only for
                    metalheads! ...so don't be
                    never afraid of the unknown!
                </p>
            </div>

            <div className="collection">
                <a href="https://novomundo13.bandcamp.com/merch">
                    <img src={collection} alt="" />
                    <span>Click and discover our tees collection!</span>
                </a>
            </div>
            <div className="bandcamp_logo">
                <a href="https://novomundo13.bandcamp.com/merch">
                    <img src={bandcamp} alt="Bandcamp NovoMundo" />
                </a>
            </div>
        </MerchMobileStyled>
        </>
    ) : (
        <MerchStyled>
            <aside>
                <div className="baktun_img">
                    <img src={baktun} alt="" />
                    <span>APPEREAL DESIGNED BY NOVOMUNDO</span>
                </div>
                <div className="description">
                    <p className="text1">
                        Explore our tees collection, and wear with us our graphics.
                        Every design is both for Woman or Man, printed with natural products and non-toxic inks.
                        We use an handmade screen printing process for give you the complete exclusivity.
                    </p>
                    <p className="text2">
                        The idea is not to offer the same old and classic band T-Shirt, but something
                        wearable to everyone.
                    </p>
                    <p className="text3">
                        Because our music and its
                        mesasage is not only for
                        metalheads! ...so don't be
                        never afraid of the unknown!
                    </p>
                </div>
                <div className="collection">
                    <a href="https://novomundo13.bandcamp.com/track/novomundo" target='_blank' rel='noreferrer'>
                        <img src={collection} alt="" />
                        <span>Click and discover our tees collection!</span>
                    </a>
                </div>
                <div className="bandcamp_logo">
                    <a href="https://novomundo13.bandcamp.com/track/novomundo">
                        <img src={bandcamp} alt="Bandcamp NovoMundo" />
                    </a>
                </div>
            </aside>
            <div className="video">
                <video src={videoMerch} autoPlay loop />
            </div>
            <div className="container">
                <div className="photo1"></div>
                <div className="photo2"></div>
                <div className="photo3"></div>
                <div className="photo4"></div>
            </div>
        </MerchStyled>
    );
}