import React from 'react';
import { FooterMobile, FooterStyled } from './style';
import logoAmazon from '../../assets/logo-amazon.png';
import logoItunes from '../../assets/logo-itunes.png';
import logoSpotify from '../../assets/logo-spotify.png';
import logomBandcamp from '../../assets/logo-BC.png';
import { useMediaQuery } from 'react-responsive';

export function Footer() {
    const isMobile = useMediaQuery({ maxWidth: 933 });

    return isMobile ? (
        <FooterMobile>
            <a href='https://novomundo13.bandcamp.com/track/novomundo' target='_blank' rel="noreferrer">
                <img className='externalLogo' src={logomBandcamp} alt="" />
            </a>
            <p><i>novomundo &copy; 2024 - website powered by Rafael Grigio</i></p>
        </FooterMobile>
    ) : (
        <FooterStyled>
            <div>
                <a target="_blank" href="https://open.spotify.com/artist/1f9Rpk40tRwOzZ9CLcvEyr?si=pmCzb_3dQJWaVReuDRA39A" rel="noreferrer">
                    <img src={logoSpotify} alt="" />
                </a>
                <a target="_blank" href="https://music.apple.com/it/artist/novomundo/1170160196" rel="noreferrer">
                    <img src={logoItunes} alt="" />
                </a>
                <a target="_blank" href="https://music.amazon.it/artists/B07J5MKPPP?ref=dm_sh_5AU5JrfbJmYXPgxDLLp5fo7VF" rel="noreferrer">
                    <img src={logoAmazon} alt="" />
                </a>
                <a target="_blank" href="https://novomundo13.bandcamp.com/track/novomundo" rel="noreferrer">
                    <img src={logomBandcamp} alt="" />
                </a>
            </div>
            <p><i>novomundo &copy; 2024 - website powered by Rafael Grigio</i></p>
        </FooterStyled>
    )
}