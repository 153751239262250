import React, { useRef } from 'react';
import { ContactStyled, ContactMobileStyled } from './style';

import fausto from '../../../assets/fausto.png'
import valerio from '../../../assets/valerio.png'
import fabio from '../../../assets/fabio.png'
import carlo from '../../../assets/carlo.png'
import { useMediaQuery } from 'react-responsive';
import emailjs from '@emailjs/browser';

let photos = {
    1: valerio,
    2: fabio,
    3: carlo,
    4: fausto,
};

let number = 1;

export function Contact() {
    const [isMounted, setIsMounted] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const myRef = useRef(null);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        emailjs
        .sendForm('service_novomundo', 'template_d4j0y3n', form.current, {
            publicKey: 'VL5FbRUPVunrxwx8r',
        })
        .then(
            () => {
            console.log('SUCCESS!');
            alert('Success! Soon we will contact you.')
            },
            (error) => {
            console.log('FAILED...', error.text);
            },
        ).finally(() => {
            setIsSubmitting(false);
        });
    };

    const isMobile = useMediaQuery({ maxWidth: 933 });

    React.useEffect(() => {
        let intervalId;
        let timeoutId1;
        let timeoutId2;

        if (!isMounted && myRef.current) {
            setIsMounted(true);
            myRef.current.src = photos[1]

            if (photos[number + 1]) {
                const img = new Image();
                img.src = photos[number + 1];
            }
        } else if (!isMobile && myRef?.current) {
            intervalId = setInterval(() => {
                let photobio = document.querySelector('.photoArtist');
                photobio.classList.add("opacity");

                const img = new Image();
                img.src = photos[number];

                timeoutId2 = setTimeout(() => {
                    myRef.current.src = photos[number]
                    photobio.classList.remove("opacity")

                    if (photos[number + 1]) {
                        const img = new Image();
                        img.src = photos[number + 1];
                    }
                }, 750);
        
                if (number === 4) {
                    number = 1;
                } else {
                    number++
                }
            }, 3500)    
        }

        return () => {
            window.clearTimeout(timeoutId1);
            window.clearTimeout(timeoutId2);
            window.clearInterval(intervalId);
        }
    }, [isMounted, isMobile]);

    return isMobile ? (
        <ContactMobileStyled>
            <p>CONTACTS</p>

            <form ref={form} onSubmit={sendEmail}>
                <input type="text" id="nome" name="nome" placeholder="name"/>
                <input type="email" id="email" name="email" placeholder="email"/>
                <textarea id="msg" cols="30" rows="5" placeholder="your text here"></textarea>
                <button disabled={isSubmitting}><i class="fa-solid fa-paper-plane"></i></button>
            </form>

            <iframe width="100%" height="260px" src="https://www.youtube.com/embed/TFEn0cNn9vo?si=8N04PPXurc6f6Ndi" title="YouTube video player" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; gyroscope; web-share" allowfullscreen></iframe>
        </ContactMobileStyled>
    ) : (
        <ContactStyled>
            <div>
                <img className='photoArtist' ref={myRef} alt="" />
            </div>
            <article>
                <p class="languages"><strong>CIAO</strong>, Hi, ¡Hola, Salut, Hallo, Oi, ПРИВЕТ, こんにちは, 你好, أهلاً <strong>!</strong></p>
                <div class="form-iframe-container">
                    <div className='teste'>
                        <form ref={form} onSubmit={sendEmail}>
                            <input type="text" id="nome" name="nome" placeholder="name"/>
                            <input type="email" id="email" name="email" placeholder="email"/>
                            <textarea id="msg" name="message" cols="30" rows="5" placeholder="your text here"></textarea>
                            <button disabled={isSubmitting}><i class="fa-solid fa-paper-plane"></i></button>
                        </form>
                        <div class="contact_icons">
                            <p>You can get in touch with us also using</p>
                            <div>
                                <a href="https://www.instagram.com/novomundo_13/" class="icon_contact">
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
                                <a href="https://www.facebook.com/NovomundoTribe" class="icon_contact">
                                    <i class="fa-brands fa-square-facebook"></i>
                                </a>
                                <a href="https://www.youtube.com/@Novomundo_13" class="icon_contact">
                                    <i class="fa-brands fa-youtube"></i>
                                </a>
                            </div>
                        </div>
                        <p className="email">official email <a href="mailto:novomundo@outlook.it">novomundo@outlook.it</a></p>
                    </div>
                    <aside>    
                        <iframe width="100%" height="260px" src="https://www.youtube.com/embed/TFEn0cNn9vo?si=8N04PPXurc6f6Ndi" title="NOVOMUNDO - NOVOMUNDO" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; gyroscope; web-share" allowfullscreen></iframe>
                        <iframe width="100%" style={{ margin: '20px 0' }} height="260px" src="https://www.youtube.com/embed/lzJTLkcwqyM?si=YsP_7efZ3cvzsLCz" title="NOVOMUNDO - Ritual" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; gyroscope; web-share" allowfullscreen></iframe>
                        <iframe width="100%" height="260px" src="https://www.youtube.com/embed/CArOspdVu6c?si=2xnaG0lfmaoDf-al" title="NOVOMUNDO - Antiwar" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; gyroscope; web-share" allowfullscreen></iframe>
                    </aside>
                </div>
            </article>
        </ContactStyled>
    );
}