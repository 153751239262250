import styled from "styled-components";
import pessoa1 from '../../../assets/pessoa1.png'
import pessoa2 from '../../../assets/pessoa2.png'
import pessoa3 from '../../../assets/pessoa3.png'
import pessoa4 from '../../../assets/pessoa4.png'

export const MerchMobileStyled = styled.section`
    width: 70%;
    margin: 0 auto;
    > p {
        margin: 20px 0 5px 0;
        font-size: 24px;
        color: #fff;
        text-align: center;
    }
    > span {
        color: #fff;
        font-size: 14px;
        text-align: center;
        display: block;
        margin: 0 0 14px 0;
    }
    img {
        width: 100%;
    }
    .react-slideshow-container{
        margin: 0 0 20px 0;
    }

    .each-slide-effect > div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 350px;
    }

    .slideImage {
        position: relative;
    }

    .descImage {
        position: absolute;
        top: 15px;
        right: 10px;
        z-index: 3291839128398129839128;
    }

    .each-slide-effect span:first-of-type {
        text-transform: uppercase;
        font-size: 20px;
        text-align: right;
        color: #fff;
    }

    .each-slide-effect span {
        display: block;
        font-size: 15px;
        text-align: right;
        color: #BE1823;
    }

    .default-nav {
        background: transparent!important;
        color: red!important;
    }
    .description {
        color: #fff;
    }
    .text2 {
        margin: 14px 0;
    }
    .collection {
        color: #fff;
        text-align: center;
        margin: 1rem 0 0 0;
        a {
            font-size: 10px;
            color: #fff;
            font-family: 'Montserrat';
            text-decoration: none;
        }
    }
`;

export const MerchStyled = styled.section`
    padding: 0 7.5vw;
    width: 85vw;
    height: calc(100vh - 110px);
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    > aside {
        width: 20%;
        margin: 0 0 0 1.5rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 1.5rem 0 0;
        max-width: 226px;

        .baktun_img {
            span {
                font-size: 10px;
                color: white;
                font-family: 'Montserrat';
                text-align: center;
                display: block;
                margin: 0 0 1rem 0;
            }
        }

        .description {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: white;
            font-size: 18px;
            overflow: auto;
            font-family: 'Montserrat';
            font-weight: lighter;
        }

        .collection {
            text-align: center;
            margin: 1rem 0 0 0;
            a {
                font-size: 10px;
                color: #fff;
                font-family: 'Montserrat';
                text-decoration: none;
            }
        }

        .bandcamp_logo img {
            max-width: 100%;
            max-height: 100%;
            display: block; /* Garante que a margem vertical funcione corretamente */
            margin: auto; /* Centraliza horizontalmente e verticalmente */
        }

        img {
            opacity: 1;
            width: 100%;
    
            &.opacity {
                opacity: 0;
                transition: all 750ms;
            }
        }
    }

    .video {
        grid-area: video;
        width: 20%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        video {
            height: 100%;
        }
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 60%;
        grid-gap: 1em;
    }

    .photo1::before {
        content: ""; /* Adicione um pseudo-elemento para criar um espaço reservado */
        padding-top: 100%; /* Defina o padding superior como 100% para manter o quadrado */
        display: block;
        background-image: url(${pessoa1}); /* URL da imagem de fundo */
        background-size: cover; /* Ajuste a imagem de fundo para cobrir todo o espaço */
    
    }
    
    
    .photo2::before {
        content: ""; /* Adicione um pseudo-elemento para criar um espaço reservado */
        padding-top: 100%; /* Defina o padding superior como 100% para manter o quadrado */
        display: block;
        background-image: url(${pessoa2}); /* URL da imagem de fundo */
        background-size: cover; /* Ajuste a imagem de fundo para cobrir todo o espaço */
    
    }
    
    
    .photo3::before {
        content: ""; /* Adicione um pseudo-elemento para criar um espaço reservado */
        padding-top: 100%; /* Defina o padding superior como 100% para manter o quadrado */
        display: block;
        background-image: url(${pessoa3}); /* URL da imagem de fundo */
        background-size: cover; /* Ajuste a imagem de fundo para cobrir todo o espaço */
    
    }
    
    .photo4::before {
        content: ""; /* Adicione um pseudo-elemento para criar um espaço reservado */
        padding-top: 100%; /* Defina o padding superior como 100% para manter o quadrado */
        display: block;
        background-image: url(${pessoa4}); /* URL da imagem de fundo */
        background-size: cover; /* Ajuste a imagem de fundo para cobrir todo o espaço */
    }
    
    div[class^='photo'] {
        width: 48%;
        padding-bottom: 48%;
        height: 0;
        position: relative;
    
    } 
`;
