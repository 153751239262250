import styled from 'styled-components';

export const NavBarStyled = styled.nav`
    padding: 0 7.5vw;
    width: 85vw;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    font-family: 'Montserrat';
    transition: all 1s;
    z-index: 2;

    a, span {
        color: #fff;
        text-decoration: none;
        font-size: 1.3rem;
    }

    .icons {
        a {
            padding: 0 10px;
            font-size: 30px;
        }
    }

    ul {
        overflow: hidden;
        width: auto;
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0 auto 0 1.5rem;

        li {
            padding: 0 10px;
            cursor: pointer;
            transition: all 1s;
        }
    }

    > a {
        display: flex;
        align-items: center;
        i {
            font-size: 30px;
            margin: 0 0 0 10px;
        }
    }

    .icons {
        margin: 0 25px 0 0;
    }

    ${({ activePage }) => {
        if (activePage !== 'home') {
            return `
                justify-content: unset;
                div.logo {
                    width: 20%;
                    max-width: 226px;

                    img {
                        width: 100%;
                    }
                }
                ul {
                    li {
                        padding: 0;
                        i {
                            margin: 0 0 0 10px;
                        }
                    }
                }
            `;
        }
    }}
`;

export const NavbarMobile = styled.nav`
    display: flex;
    width: 70%;
    margin: 0 auto;
    flex-wrap: wrap;
    padding: 35px 0 0 0;
    z-index: 2;
    position: relative;

    .icons {
        width: 100%;
        display: flex;
        font-size: 35px;
        margin: 0 0 15px 0;
        a {
            font-size: 35px;
            color: #fff;
            padding: 0 10px;
            &:first-of-type {
                padding: 0 10px 0 0;
            }
        }

        .fa-bars, .fa-x {
            margin: 0 0 0 auto;
            color: #D02012;
        }
    }

    .logo {
        display: flex;
        justify-content: center;
        width: 100%;
        img {
            width: 100%;
        }
    }

    .menu {
        width: 72%;
        border-radius: 15px;
        margin: 30px 0 0 0;
        position: absolute;
        z-index: 99;
        top: 160px;
        left: 14%;
        background-color: #BE1823;
        ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            list-style-type: none;
            li {
                color: #fff;
                font-size: 24px;
                margin: 15px 0;
                &:first-of-type {
                    margin: 30px 0 15px 0;
                }
                &:last-of-type {
                    margin: 15px 0 30px 0;
                }
            }
        }
    }
`; 