import React, { useRef } from 'react';
import { BioMobileStyled, BioStyled } from './style';
import fausto from '../../../assets/fausto.png'
import valerio from '../../../assets/valerio.png'
import fabio from '../../../assets/fabio.png'
import carlo from '../../../assets/carlo.png'
import banda from '../../../assets/banda.png'
import background from '../../../assets/background_mobile.png'
import { useMediaQuery } from 'react-responsive';

let photos = {
    1: valerio,
    2: fabio,
    3: carlo,
    4: fausto,
};

let number = 1;

export function Bio() {
    const [isMounted, setIsMounted] = React.useState(false);
    const myRef = useRef(null)
    const isMobile = useMediaQuery({ maxWidth: 933 });

    React.useEffect(() => {
        let intervalId;
        let timeoutId1;
        let timeoutId2;

        if (!isMounted && myRef.current) {
            setIsMounted(true);
            myRef.current.src = photos[1]

            if (photos[number + 1]) {
                const img = new Image();
                img.src = photos[number + 1];
            }

        } else if (!isMobile && myRef?.current) {
            intervalId = setInterval(() => {
                let photobio = document.querySelector('.photoArtist');
                photobio.classList.add("opacity");
                
                const img = new Image();
                img.src = photos[number];

                timeoutId2 = setTimeout(() => {
                    myRef.current.src = photos[number]
                    photobio.classList.remove("opacity")

                    if (photos[number + 1]) {
                        const img = new Image();
                        img.src = photos[number + 1];
                    }
                }, 750);
        
                if (number === 4) {
                    number = 1;
                } else {
                    number++
                }
            }, 3500)    
        }

        return () => {
            window.clearTimeout(timeoutId1);
            window.clearTimeout(timeoutId2);
            window.clearInterval(intervalId);
        }
    }, [isMounted, isMobile]);

    return isMobile ? (
        <BioMobileStyled>
            <p>BIO</p>

            <div className="text">
                <p>
                    <strong>Novomundo</strong>, project that <strong>was born in 2012</strong>,  is an italian metal band based in a small town close to <strong>Rome</strong>. The idea was to forge some heavy and rude music mixed with ethnic instrument from all over the world, singing about stories and concepts based on primitive and exotic cultures.
                </p>
                <p>
                    We sing against war and violece, we tell about human&apos;s limits, we want to share with all of you the love for freedom and simplicity. We believe in equality, we trust in humans values and power.
                </p>
                <p>
                    We live and love this little stone lost in dark, and we want to save it from the
                    sickness of this modern days, we want to help people to find themselves.</p>
                <p>
                    We fight agaist hate, unjustice and suffering. We don&apos;t care about politics, we do not believe in any racial herarchy, we have no religion or holy men to pray.
                    With our music and passion, we make the world a better and louder place.
                </p>
                <p>
                    We are Novomundo.
                </p>
            </div>

            <div className="image">
                <img src={background} alt="background" />
            </div>

            <div className="representatives">
                <p><strong>LINE UP</strong></p>
                <p>Valerio Cristiani <i>Vocal</i></p>
                <p>Carlo Ridolfi <i>Guitar</i></p>
                <p>Fabio Ciccone <i>Guitar</i></p>
                <p>Leonardo Sapio <i>Bass</i></p>
                <p>Fausto Idini <i>Drums</i></p>
            </div>
        </BioMobileStyled>
    ) : (
        <BioStyled>
            <div>
                <img ref={myRef} className='photoArtist' alt="" />
            </div>

            <article className="bio">
                <div className="text">
                    <p>
                        <strong>Novomundo</strong>, project that <strong>was born in 2012</strong>,  is an italian metal band based in a small town close to <strong>Rome</strong>. The idea was to forge some heavy and rude music mixed with ethnic instrument from all over the world, singing about stories and concepts based on primitive and exotic cultures.
                    </p>
                    <p>
                        We sing against war and violece, we tell about human&apos;s limits, we want to share with all of you the love for freedom and simplicity. We believe in equality, we trust in humans values and power.
                    </p>
                    <p>
                        We live and love this little stone lost in dark, and we want to save it from the
                        sickness of this modern days, we want to help people to find themselves.</p>
                    <p>
                        We fight agaist hate, unjustice and suffering. We don&apos;t care about politics, we do not believe in any racial herarchy, we have no religion or holy men to pray.
                        With our music and passion, we make the world a better and louder place.
                    </p>
                    <p>
                        We are Novomundo.
                    </p>
                </div>
                <div className="representatives">
                    <p><strong>LINE UP</strong></p>
                    <p>Valerio Cristiani <i>Vocal</i></p>
                    <p>Carlo Ridolfi <i>Guitar</i></p>
                    <p>Fabio Ciccone <i>Guitar</i></p>
                    <p>Leonardo Sapio <i>Bass</i></p>
                    <p>Fausto Idini <i>Drums</i></p>
                </div>
            </article>
            <aside>
                <img src={banda} alt="" />
                <span><i>Novomundo &copy; 2012</i></span>
            </aside>
        </BioStyled>
    );
}