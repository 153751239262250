import React from "react";
import { AppStyle } from "./AppStyle";
import { Footer } from "./components/footer";
import { Navbar } from "./components/navbar";
import { Bio } from "./components/pages/bio";
import { Merch } from "./components/pages/merch";
import { Contact } from "./components/pages/contact";
import { useMediaQuery } from "react-responsive";
import video from './assets/backsite.mp4'

function App() {
  const [page, setPage] = React.useState('home')
  const isMobile = useMediaQuery({ maxWidth: 933 })

  return (
    <AppStyle isMobile={isMobile}>
      <Navbar activePage={page} setPage={setPage} />

      {page === 'home' && (
        <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', top: '0', left: '0', backgroundColor: '#000', zIndex: '0', width: '100vw', height: '100vh', overflow: 'hidden', width: '100%' }}>
          <video className="background-video" autoPlay loop muted>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      )}

      {page === 'bio' && (
        <Bio />
      )}

      {page === 'merch' && (
        <Merch />
      )}

      {page === 'contacts' && (
        <Contact />
      )}


      {page === 'home' && (
        <Footer />
      )}
    </AppStyle>
  );
}

export default App;
