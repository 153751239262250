import styled from 'styled-components';

export const AppStyle = styled.div`
    position: relative;
    p, span, h1, h2, h3 {
        font-family: 'Montserrat';
    }
    width: 100vw;
    height: 100vh;
        ${({ isMobile }) => isMobile ? `
        .background-video {
            overflow: hidden;
            height: 100%;
            margin: 0 auto;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            z-index: 0;
        }
    ` : `
        .background-video {
            width: 100%;
        }
    `}
`;
