import styled from "styled-components";

export const BioStyled = styled.section`
    padding: 0 7.5vw;
    width: 85vw;
    height: calc(100vh - 110px);
    display: flex;
    justify-content: space-between;

    > div {
        overflow: hidden;
        display: flex;
        width: 20%;
        max-width: 226px;
        justify-content: center;
        margin: 0 1.5rem 0 0;

        img {
            opacity: 1;
            transition: all 450ms;
            height: 100%;
    
            &.opacity {
                opacity: 0;
                transition: all 750ms;
            }
        }
    }

    p, span {
        color: white;
        font-family: 'Montserrat';
        font-weight: lighter;
        font-size: 20px;
    }

    article {
        width: 60%;
        overflow-x: hidden;
        
        div.text {
            p:nth-child(2n+2) {
                margin: 15px 0;
            }

            margin: 0 0 15px 0;
        }
    }

    aside {
        width: 20%;
        margin: 0 0 0 1rem;
        img {
            width: 100%;
        }

        span {
            font-size: 10px;
        }
    }
`;

export const BioMobileStyled = styled.section`
    width: 70%;
    margin: 0 auto;

    > p {
        margin: 20px 0;
        font-size: 24px;
        color: #fff;
        text-align: center;
    }

    .text {
        color: #fff;
        p {
            margin: 0 0 15px 0;
        }
    }

    .representatives {
        color: #fff;
        padding: 0 0 40px 0;
    }

    .image {
        width: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        img {
            margin: 20px 0;
            width: 100%;
        }
    }
`;
