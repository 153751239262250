import styled from 'styled-components';

export const FooterStyled = styled.footer`
    display: flex;
    margin: 0 0 15px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    div {
        display: flex;
        align-items: center;
        margin: 0 0 15px 0;

        & img {
            width: 85%;
        }
    }

    p {
        color: white;
        font-family: 'Montserrat';
        font-weight: lighter;
    }
`;

export const FooterMobile = styled.footer`
    overflow: hidden;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100vw;
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #000;
    }
    p {
        font-size: 12px;
        margin: 15px 0 0 0;
        text-align: center;
        width: 100%;
        text-wrap: wrap;
        color: #fff;
        text-shadow: 0px 0px 10px #000;
    }
`;
