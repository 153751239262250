import styled from "styled-components";

export const ContactMobileStyled = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    padding: 10px 0 0 0;
    overflow: hidden;
    > p {
        margin: 20px 0;
        font-size: 24px;
        color: #fff;
        text-align: center;
    }
    form {
            position: relative;
            display: flex;
            flex-direction: column;
            background-color: rgb(193, 39, 45);
            padding: 25px;
            box-sizing: border-box;
            width: 100%;
            margin: 0 16px 15px 16px;

            button {
                position: absolute;
                bottom: 30px;
                right: 30px;
                padding: 12px;
                width: fit-content;
                border-radius: 25px;
                background-color: black;
                border: none;
                &:disabled {
                    background-color: #7d7d7d;
                }
                i {
                    color: white;
                    font-size: 20px;
                }
            }

            textarea {
                border-radius: 15px;
                padding: 10px;
                resize: none;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
            }

            input {
                border-radius: 18px;
                padding: 10px;
                border: none;
            }
            
            input:last-of-type {
                margin: 10px 0;
            }
            
            textarea {
                border-radius: 18px;
                padding: 10px;
                resize: none;
            }
        }
`;

export const ContactStyled = styled.section`
    padding: 0 7.5vw;
    width: 85vw;
    height: calc(100vh - 110px);
    display: flex;

    p, span, h1, h2, h3, h4, h5, h6 {
        color: white;
        font-family: 'Montserrat';
        font-weight: lighter;
    }

    > div {
        overflow: hidden;
        display: flex;
        width: 20%;
        max-width: 226px;
        justify-content: center;
        margin: 0 1.5rem 0 0;

        img {
            opacity: 1;
            transition: all 450ms;
            height: 100%;
    
            &.opacity {
                opacity: 0;
                transition: all 750ms;
            }
        }
    }

    article {
        width: 80%;
        overflow-x: hidden;

        > div {
            display: flex;
            justify-content: space-between;
        }
        
        .text {
            p:nth-child(2n+2) {
                margin: 15px 0;
            }

            margin: 0 0 15px 0;
        }

        .languages {
            font-size: 28px;
        }

        aside {
            width: 35%;
        }

        .teste {
            width: calc(100% - 290px);
        }

        form {
            position: relative;
            display: flex;
            flex-direction: column;
            background-color: rgb(193, 39, 45);
            padding: 25px;
            margin: 0 16px 0 0;
            max-height: 300px;

            button {
                position: absolute;
                bottom: 30px;
                right: 30px;
                padding: 12px;
                width: fit-content;
                border-radius: 25px;
                background-color: black;
                border: none;
                &:disabled {
                    background-color: #7d7d7d;
                }
                i {
                    color: white;
                    font-size: 20px;
                }
            }

            textarea {
                border-radius: 15px;
                padding: 10px;
                resize: none;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
            }

            input {
                border-radius: 18px;
                padding: 10px;
                border: none;
            }
            
            input:last-of-type {
                margin: 10px 0;
            }
            
            textarea {
                border-radius: 18px;
                padding: 10px;
                resize: none;
            }
        }
    }

    .form-iframe-container {
        display: flex;

    }

    .contact_icons {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        margin: 20px 0 0 0;
        font-size: 21px;
        div {
            margin: 0 0 0 10px;
            display: flex;
            gap: 15px;
        }
        a {
            text-decoration: none;
            margin: 0 0 0 10%;
            font-size: 30px;
            color: #fff;
        }
    }

    .email {
        font-size: 21px;
        margin: 50px 0 0 0;

        a {
            text-decoration: none;
            color: rgb(193, 39, 45);
        }
    }
`;
